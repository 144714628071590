import React, { useEffect } from "react";
import "./App.css";
import Login from "./Login/Login";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import SubScription from "./Components/SubScription";
import Settings from "./Components/Settings";
import Reviews from "./Views/Pages/Reviews";
import Home from "./Views/Pages/Home";
import Menu from "./Views/Pages/Menu";
import Buyers from "./Views/Pages/Buyers";
import ForgotPassword from "./Login/ForgotPassword";
import Otp from "./Login/Otp";
import ResetPasswordForm from "./Login/ResetPassword";
import Loader from "./Common/Loader";
import { useLoading } from "./Context/LoadingContext";
import OffcanvasComponent from "./Components/OffcanvasComponent";
import OrderConfirmationModal from "./Components/orderConfirmationModal";
import OrderSuccessModal from "./Components/OrderStatusModal";
import Payment from "./Views/Pages/Payment";
import PaymentFailed from "./Views/Pages/PaymentFailed";
import PaymentSuccess from "./Views/Pages/PaymentSuccess";
import SubscriptionPaymentSuccess from "./Views/Pages/SubscriptionPaymentSuccess";
import SubscriptionPaymentFailed from "./Views/Pages/SubscriptionPaymentFailed";
import Profile from "./Views/Pages/Profile";
import SubscriptionPayment from "./Views/Pages/PaymentSubscription";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const navigate = useNavigate();
  const {
    loading,
    offCanvasShow,
    setOrderConfirm,
    orderConfirm,
    selectedCheckoutData,
    setSelectedCheckoutData,
    orderStatus,
    setOrderStatus,
  } = useLoading();

  // useEffect(() => {
  //   const localToken = localStorage.getItem("accessToken");
  //   if (!localToken) {
  //     navigate("/");
  //   }
  // }, [navigate]);

  return (
    <div className="App">
      {loading && <Loader />}
      {offCanvasShow && (
        <OffcanvasComponent
          setOrderConfirm={setOrderConfirm}
          orderConfirm={orderConfirm}
          selectedCheckoutData={selectedCheckoutData}
          setSelectedCheckoutData={setSelectedCheckoutData}
        />
      )}
      {orderConfirm && (
        <OrderConfirmationModal setOrderStatus={setOrderStatus} />
      )}
      {orderStatus && (
        <OrderSuccessModal setOrderStatus={setOrderStatus} orderStatus={orderStatus} />
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/buyers" element={<Buyers />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/subScriptions" element={<SubScription />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/resetPassword" element={<ResetPasswordForm />} />
        <Route path="/orderPayment/:orderID" element={<Payment />} />
        <Route path="/Paymentfailed" element={<PaymentFailed />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/subscriptionOrderPayment/:orderID" element={<SubscriptionPayment />} />
        <Route path="/subscriptionPaymentfailed" element={<SubscriptionPaymentFailed />} />
        <Route path="/subscriptionPaymentSuccess" element={<SubscriptionPaymentSuccess />} />
      </Routes>
    </div>
  );
}

export default App;
