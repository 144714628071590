import React, { useState, useEffect } from "react";
import Sidebar from "../../Common/Sidebar";
import Header from "../../Common/Header";
import { PostJsonData } from "../../Api/globalApi";
import { useLoading } from "../../Context/LoadingContext";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import Skeleton CSS
import {
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
  CBadge,
} from "@coreui/react";
import PaginationComponent from "../../Common/Pagination";

const Buyers = () => {
  const { setLoading } = useLoading();
  const [buyers, setBuyers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Local loading state
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchBuyers = async (searchText, pageNo) => {
    try {
      // setLoading(true);
      setIsLoading(true);
      const jsonData = {
        isFilter: 0,
        isSubscription: 0,
        pageNo: pageNo,
        searchText: searchText || "",
      };
      const response = await PostJsonData("/Api/getBuyers", jsonData);
      if (response?.apiResponseCode === "1001") {
        setBuyers(response.data);
        setPageLimit(response.pageLimit);
        setTotalRecords(response.totalRecords);
      } else {
        setBuyers([]);
      }
    } catch (error) {
      console.error("Failed to fetch buyers:", error);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchBuyers(searchText, 1);
      setPageNo(1);
    }, 700);
    return () => clearTimeout(handler);
  }, [searchText]);
  const callGetData = (page) => {
    fetchBuyers(searchText, page);
  };

  const skeletonRows = Array.from({ length: 10 }, (_, i) => i);

  return (
    <Container fluid>
      <Row className="main_container">
        <Col lg={2} className="outer_sidebar padding_0">
          <Sidebar />
        </Col>

        <Col lg={10} className="outer_main_container">
          <div className="main_home_outer">
            <Header setSearchText={setSearchText} searchText={searchText} />

            <Container fluid className="mt-4">
              <CTable
                className="category"
                style={{ textAlign: "left" }}
                responsive
              >
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Sr.No</CTableHeaderCell>
                    <CTableHeaderCell>Image</CTableHeaderCell>
                    <CTableHeaderCell>Buyer Name</CTableHeaderCell>
                    <CTableHeaderCell>Email</CTableHeaderCell>
                    <CTableHeaderCell>Mobile No</CTableHeaderCell>
                    <CTableHeaderCell>D.O.B</CTableHeaderCell>
                    <CTableHeaderCell>Remaining Coffee</CTableHeaderCell>
                    <CTableHeaderCell>Subscription</CTableHeaderCell>
                    <CTableHeaderCell>Created At</CTableHeaderCell>
                    <CTableHeaderCell>Status</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {isLoading ? (
                    skeletonRows?.map((_, index) => (
                      <CTableRow key={index}>
                        <CTableDataCell>
                          <Skeleton width={20} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton circle width={40} height={40} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={100} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={100} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={100} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={100} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={50} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={50} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={60} />
                        </CTableDataCell>
                        <CTableDataCell>
                          <Skeleton width={60} />
                        </CTableDataCell>
                      </CTableRow>
                    ))
                  ) : buyers?.length > 0 ? (
                    buyers?.map((buyer, index) => (
                      <CTableRow key={index}>
                        <CTableDataCell>
                          {(pageNo - 1) * pageLimit + index + 1}
                        </CTableDataCell>
                        <CTableDataCell>
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              buyer?.imageURL
                            }
                            alt="not found"
                            className="buyer_img"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "./images/noimagefound.png";
                            }}
                          />
                        </CTableDataCell>
                        <CTableDataCell>{buyer?.name || "N/A"}</CTableDataCell>
                        <CTableDataCell>{buyer?.email || "N/A"}</CTableDataCell>
                        <CTableDataCell>
                          {buyer?.mobileNo || "N/A"}
                        </CTableDataCell>
                        <CTableDataCell>{buyer?.dob || "N/A"}</CTableDataCell>
                        <CTableDataCell>
                          {buyer?.remainingCoffee || "-"}
                        </CTableDataCell>
                        <CTableDataCell>
                          <p
                            className={
                              buyer?.isSubscription
                                ? "paid-color"
                                : "unpaid-color"
                            }
                          >
                            {buyer.isSubscription ? "Yes" : "No"}
                          </p>
                        </CTableDataCell>
                        <CTableDataCell>
                          {buyer?.createdAt
                            ? new Date(buyer.createdAt).toLocaleString()
                            : "N/A"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {buyer?.isVerified ? (
                            <CBadge
                              style={{ padding: "7px 8px" }}
                              color="success"
                            >
                              Verified
                            </CBadge>
                          ) : (
                            <CBadge
                              style={{ padding: "7px 8px" }}
                              color="danger"
                            >
                              Not Verified
                            </CBadge>
                          )}
                        </CTableDataCell>
                      </CTableRow>
                    ))
                  ) : (
                    <CTableRow>
                      <CTableDataCell colSpan="10" className="text-center">
                        No buyers found.
                      </CTableDataCell>
                    </CTableRow>
                  )}
                </CTableBody>
              </CTable>
              {!isLoading && buyers?.length ? (
                <PaginationComponent
                  totalRecords={totalRecords}
                  pageLimit={pageLimit}
                  setTotalRecords={setTotalRecords}
                  setPageLimit={setPageLimit}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  callGetData={callGetData}
                />
              ) : (
                ""
              )}
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Buyers;
