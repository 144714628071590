import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CButton } from "@coreui/react";
import { PostJsonData } from "../../Api/globalApi";

const SubscriptionPaymentFailed = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionID = queryParams.get("session_id");
  const navigate = useNavigate();
  // const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  console.log(sessionID, "sessionID");
  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch(
          `https://api.stripe.com/v1/checkout/sessions/${sessionID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            },
          }
        );

        const sessionData = await response.json();

        if (response.ok) {
          // setPaymentDetails(sessionData);
          // Send failure to backend if payment failed
          await updateOrderTransaction(sessionData, "FAILED");
        } else {
          console.error("Error fetching payment details:", sessionData.error);
        }
      } catch (error) {
        console.error("Error fetching payment details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (sessionID) {
      fetchPaymentDetails();
    }
  }, [sessionID]);

  const updateOrderTransaction = async (sessionData, paymentStatus) => {
    const orderTransactionData = {
      netAmount: (sessionData.amount_total / 100).toString(),
      paymentStatus: paymentStatus,
      responseData: JSON.stringify(sessionData),
      transactionNo: sessionData.client_reference_id,
    };

    try {
      const response = await PostJsonData(
        "/updateSubscriptionOrderTransaction",
        orderTransactionData
      );

      if (response.ok) {
        console.log("Order transaction updated successfully");
      } else {
        console.error("Failed to update order transaction");
      }
    } catch (error) {
      console.error("Error updating order transaction:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="outer-suceess-container">
      <div className="message-box _success _failed">
        <i className="fa fa-times-circle" aria-hidden="true"></i>
        <h2>Your payment failed</h2>
        <p>Try again later</p>

        <CButton
          className="gobackHome"
          color="danger"
          onClick={() => navigate("/home")}
        >
          Go back to dashboard
        </CButton>
      </div>
    </div>
  );
};

export default SubscriptionPaymentFailed;
