import React, { useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
// import "../App.css";
import ReadMore from "../../Components/ReadMore";

const Review = () => {
  const [showReadMore, setShowReadMore] = useState(false);
  return (
    <>
      {showReadMore && (
        <ReadMore
          showReadMore={showReadMore}
          setShowReadMore={setShowReadMore}
        />
      )}
      <Container fluid>
        <Row className="main_container">
          <Col lg={2} className="outer_sidebar padding_0">
            <Sidebar />
          </Col>
          <Col lg={10} className="outer_main_container">
            <div className="main_home_outer">
              <Header />
              <Container fluid>
                <div className="category profile_bg">
                  <Row>
                    <Col>
                      <h4 className="sub-heading">Reviews</h4>
                      <p className="sub-breadcrumbs">Home / Reviews</p>
                    </Col>
                    <Col className="text-end">
                      <span className="total-rev">
                        Total Reviews{" "}
                        <span className="total-reviews review-count">1</span>
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    {Array.from({ length: 1 }).map((_, idx) => (
                      <Col lg={4} md={6} className="mb-4" key={idx}>
                        <Card className="review-card">
                          <Card.Body>
                            <Card.Title>
                              Rahul Kumar <span className="stars">★★★★★</span>
                            </Card.Title>
                            <Card.Text>
                              Designers Collab is more than just a coffee shop
                              web application—it’s a destination for coffee
                              lovers. Whether you’re a casual drinker or a
                              connoisseur, this platform offers everything you
                              need to enjoy the perfect cup of coffee. It’s a
                              shining example of how a well-designed e-commerce
                              app can elevate the shopping experience.
                              <span
                                onClick={() => setShowReadMore(true)}
                                className="readmore"
                              >
                                {" "}
                                [Read More]
                              </span>{" "}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Review;
