import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PostJsonData } from "../../Api/globalApi";
import { stripePromise } from "../../Api/stripeUtils";

const SubscriptionPayment = () => {
  const { orderID } = useParams(); // Extract orderID from URL params
  useEffect(() => {
    const processSubscriptionPayment = async () => {
      try {
        if (!orderID) {
          console.error("Order ID is missing");
          return;
        }

        const transactionPayload = { orderID };
        const transactionResponse = await PostJsonData(
          "/setSubscriptionOrderTransaction",
          transactionPayload
        );

        console.log("API Response:", transactionResponse);

        if (transactionResponse.apiResponseCode === "1001") {
          // setTransactionData(transactionResponse.data);

          const stripe = await stripePromise;
          if (!stripe) {
            console.error("Stripe is not initialized correctly.");
            return;
          }

          const packageDetail = transactionResponse.data.packageDetail;
          const intervalMapping = {
            DAILY: "day",
            WEEKLY: "week",
            MONTHLY: "month",
            YEARLY: "year",
          };
          const interval = intervalMapping[packageDetail?.productType];

          // Line item for the package
          const lineItems = [
            {
              price_data: {
                currency: "usd",
                product_data: {
                  name: packageDetail?.productName,
                  description: packageDetail?.desctiption?.String || "",
                },
                unit_amount: packageDetail?.price * 100, // Package price in cents
                recurring: { interval },
              },
              quantity: packageDetail?.quantity,
            },
            {
              // Line item for sales tax
              price_data: {
                currency: "usd",
                product_data: {
                  name: "Sales Tax",
                  description: "Applicable sales tax for the subscription.",
                },
                unit_amount: transactionResponse.data.saleTaxAmount * 100, // Sales tax in cents
              },
              quantity: 1,
            },
          ];

          // Prepare request body
          const params = new URLSearchParams();
          params.append(
            "success_url",
            `${window.location.origin}/subscriptionPaymentSuccess?session_id={CHECKOUT_SESSION_ID}`
          );
          params.append(
            "cancel_url",
            `${window.location.origin}/subscriptionPaymentfailed?session_id={CHECKOUT_SESSION_ID}`
          );
          params.append("mode", "subscription");
          params.append("customer_email", transactionResponse.data.buyeEmail);
          params.append("client_reference_id", transactionResponse.data.transactionNo);

          // Append line items to params
          lineItems.forEach((item, index) => {
            params.append(
              `line_items[${index}][price_data][currency]`,
              item.price_data.currency
            );
            params.append(
              `line_items[${index}][price_data][product_data][name]`,
              item.price_data.product_data.name
            );
            params.append(
              `line_items[${index}][price_data][product_data][description]`,
              item.price_data.product_data.description
            );
            params.append(
              `line_items[${index}][price_data][unit_amount]`,
              item.price_data.unit_amount
            );
            if (item.price_data.recurring) {
              params.append(
                `line_items[${index}][price_data][recurring][interval]`,
                item.price_data.recurring.interval
              );
            }
            params.append(`line_items[${index}][quantity]`, item.quantity);
          });

          // Add metadata for additional context
          params.append("metadata[sale_tax_amount]", transactionResponse.data.saleTaxAmount);
          params.append("metadata[net_amount]", transactionResponse.data.netAmount);

          const response = await fetch(
            "https://api.stripe.com/v1/checkout/sessions",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
              },
              body: params.toString(),
            }
          );

          const session = await response.json();

          if (session.id) {
            localStorage.setItem("session", session?.id);
            const result = await stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (result.error) {
              console.error(
                "Error redirecting to checkout:",
                result.error.message
              );
            }
          } else {
            console.error("Failed to create a Stripe session:", session.error);
          }
        } else {
          console.error(
            "Subscription Transaction API failed:",
            transactionResponse.message
          );
        }
      } catch (error) {
        console.error("Error processing subscription payment:", error);
      }
    };

    processSubscriptionPayment();
  }, [orderID]);

  // Render transaction details
  return null;
};

export default SubscriptionPayment;
