import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PostJsonData } from "../../Api/globalApi";
import { stripePromise } from "../../Api/stripeUtils";

const Payment = () => {
  const { orderID } = useParams(); // Extract orderID from URL params

  useEffect(() => {
    const processPayment = async () => {
      try {
        if (!orderID) {
          console.error("Order ID is missing");
          return;
        }

        // Fetch transaction details
        const transactionPayload = { orderID };
        const transactionResponse = await PostJsonData(
          "/setOrderTransaction",
          transactionPayload
        );

        if (transactionResponse.apiResponseCode === "1001") {
          // Extract data directly from the response
          const {
            orderID,
            orderNo,
            transactionNo,
            buyerName,
            buyeEmail,
            netAmount,
            saleTaxAmount,
            products,
          } = transactionResponse.data;

          // Store the response data in the state
          // setTransactionData(transactionResponse.data);

          // Map products data to Stripe line items
          const lineItems = products.map((product) => ({
            price_data: {
              currency: "usd",
              product_data: {
                name: product.productName,
              },
              unit_amount: product.price * 100, // Directly use the price as a float, but round it to the nearest integer (in cents)
            },
            quantity: product.quantity,
          }));

          // Add sales tax (round to the nearest integer in cents)
          if (saleTaxAmount) {
            lineItems.push({
              price_data: {
                currency: "usd",
                product_data: {
                  name: "Sales Tax",
                },
                unit_amount: saleTaxAmount * 100, // Round the sales tax to nearest integer (in cents)
              },
              quantity: 1,
            });
          }

          // Log the line items for debugging
          console.log("Line items for Stripe:", JSON.stringify(lineItems, null, 2));

          // Initialize Stripe
          const stripe = await stripePromise;

          if (!stripe) {
            console.error("Stripe is not initialized correctly.");
            return;
          }

          // Set up the request body for Stripe Checkout in URL-encoded format
          const params = new URLSearchParams();
          params.append(
            "success_url",
            `${window.location.origin}/PaymentSuccess?session_id={CHECKOUT_SESSION_ID}`
          );
          params.append(
            "cancel_url",
            `${window.location.origin}/PaymentFailed?session_id={CHECKOUT_SESSION_ID}`
          );
          params.append("mode", "payment");
          params.append("customer_email", buyeEmail);
          params.append("client_reference_id", transactionNo);  // Use the transactionNo from the response

          // Append line_items as a stringified array
          lineItems.forEach((item, index) => {
            params.append(
              `line_items[${index}][price_data][currency]`,
              item.price_data.currency
            );
            params.append(
              `line_items[${index}][price_data][product_data][name]`,
              item.price_data.product_data.name
            );
            params.append(
              `line_items[${index}][price_data][unit_amount]`,
              item.price_data.unit_amount // Use the integer value here (in cents)
            );
            params.append(`line_items[${index}][quantity]`, item.quantity);
          });

          // Log the params for debugging
          console.log("URLSearchParams for Stripe:", params.toString());

          // Make the request to create the checkout session with URLSearchParams
          const response = await fetch(
            "https://api.stripe.com/v1/checkout/sessions",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded", // Set the content type to form-encoded
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
              },
              body: params.toString(), // Send the body as URL-encoded
            }
          );

          const session = await response.json();

          if (session.id) {
            // Redirect to Stripe Checkout
            localStorage.setItem("session", session?.id);
            const result = await stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (result.error) {
              console.error("Error redirecting to checkout:", result.error.message);
            }
          } else {
            console.error("Failed to create a Stripe session:", session.error);
          }
        } else {
          console.error("Transaction API failed:", transactionResponse.message);
        }
      } catch (error) {
        console.error("Error processing payment:", error);
      }
    };

    processPayment();
  }, [orderID]);

  return null; // No UI rendering needed for this component
};

export default Payment;
