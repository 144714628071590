import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CButton } from "@coreui/react";
import { PostJsonData } from "../../Api/globalApi";

const SubscriptionPaymentSuccess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionID = queryParams.get("session_id");
  const navigate = useNavigate();
  // const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch(
          `https://api.stripe.com/v1/checkout/sessions/${sessionID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            },
          }
        );

        const sessionData = await response.json();

        if (response.ok) {
          // setPaymentDetails(sessionData);

          // Check payment status
          const paymentStatus = sessionData.payment_status;
          if (paymentStatus === "paid") {
            // Send success to backend
            await updateOrderTransaction(sessionData, "SUCCESS");
          } else {
            // Send failure to backend
            await updateOrderTransaction(sessionData, "FAILED");
          }
        } else {
          console.error("Error fetching payment details:", sessionData.error);
        }
      } catch (error) {
        console.error("Error fetching payment details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (sessionID) {
      fetchPaymentDetails();
    }
  }, [sessionID]);

  const updateOrderTransaction = async (sessionData, paymentStatus) => {
    const orderTransactionData = {
      netAmount: (sessionData.amount_total / 100).toString(),
      paymentStatus: paymentStatus,
      responseData: JSON.stringify(sessionData),
      transactionNo: sessionData.client_reference_id,
    };

    try {
      const response = await PostJsonData(
        "/updateSubscriptionOrderTransaction",
        orderTransactionData
      );

      if (response.ok) {
        console.log("Order transaction updated successfully");
      } else {
        console.error("Failed to update order transaction");
      }
    } catch (error) {
      console.error("Error updating order transaction:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="success-body">
      <div className="success-card">
        <div className="success-icon-container">
          <i className="success-checkmark">✓</i>
        </div>
        <h1 className="success-heading">Your payment was successful</h1>
        <p className="success-message">
          Thank you for your payment.
          <br />
          <CButton
            className="gobackHome"
            color="success"
            onClick={() => navigate("/home")}
          >
            Go back to dashboard
          </CButton>
        </p>

        {/* {paymentDetails && (
          <div className="payment-details">
            <h3>Payment Details</h3>
            <ul>
              <li>Transaction ID: {paymentDetails.id}</li>
              <li>Payment Intent: {paymentDetails.payment_intent}</li>
              <li>
                Amount Paid: ${(paymentDetails.amount_total / 100).toFixed(2)}{" "}
                USD
              </li>
              <li>Customer Email: {paymentDetails.customer_email}</li>
            </ul>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SubscriptionPaymentSuccess;
