import React, { useEffect, useState } from "react";
import { CPagination, CPaginationItem } from "@coreui/react";

const PaginationComponent = ({
  totalRecords,
  pageLimit,
  pageNo,
  setPageNo,
  callGetData,
}) => {
  const [prevPage, setPrevPage] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const calculatedTotalPages = Math.ceil(totalRecords / pageLimit);
    setTotalPages(calculatedTotalPages);

    const startPage = pageNo - 2 < 1 ? 1 : pageNo - 2;
    const endPage = pageNo + 2 > calculatedTotalPages ? calculatedTotalPages : pageNo + 2;
    const pages = [];

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    setPageNumbers(pages);

    setPrevPage(pageNo > 1);
    setNextPage(pageNo < calculatedTotalPages);
  }, [totalRecords, pageLimit, pageNo]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPageNo(newPage);
      callGetData(newPage);
    }
  };

  const previousButton = () => {
    if (pageNo > 1) {
      handlePageChange(pageNo - 1);
    }
  };

  const nextButton = () => {
    if (pageNo < totalPages) {
      handlePageChange(pageNo + 1);
    }
  };

  if (totalPages <= 1) return null;

  return (
    <div className="d-flex justify-between" style={{ width: "100%", alignItems: "center", justifyContent: "space-between" }}>
      <span className="pagination-text">
        Showing {(pageNo - 1) * pageLimit + 1} to {Math.min(pageNo * pageLimit, totalRecords)} of {totalRecords}
      </span>
      <CPagination className="pagination_custom" align="end">
        <CPaginationItem disabled={!prevPage} onClick={previousButton}>
          <span className="pagination_next_prev">
            <img src="./images/previous.svg" alt="previous" /> Previous
          </span>
        </CPaginationItem>

        {pageNumbers?.map((value) => (
          <CPaginationItem
            key={value}
            active={value === pageNo}
            onClick={() => handlePageChange(value)}
          >
            {value}
          </CPaginationItem>
        ))}

        <CPaginationItem disabled={!nextPage} onClick={nextButton}>
          <span className="pagination_next_prev">
            Next <img src="./images/next.svg" alt="next_btn" />
          </span>
        </CPaginationItem>
      </CPagination>
    </div>
  );
};

export default PaginationComponent;
