import React, { useState, useEffect } from "react";
import Cards from "../../Components/Cards";
import ModalCard from "../../Components/ModalCard";
import Sidebar from "../../Common/Sidebar";
import { Col, Row, Container } from "react-bootstrap";
import "../../App.css";
import PaginationComponent from "../../Common/Pagination";
import OrderStatsModal from "../../Components/OrderStatsModal";
import Header from "../../Common/Header";
import { PostJsonData } from "../../Api/globalApi";
import Skeleton from "react-loading-skeleton";

const Menu = () => {
  const [categories, setCategories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [show, setShow] = useState(false);
  const [orderStats, setOrderStats] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCategoryID, setSelectedCategoryID] = useState(null);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const handleProductClick = (index) => {
    setSelectedProduct(index);
    const selectedCategory = categories[index];
    setSelectedCategoryID(selectedCategory.categoryID);
    fetchProducts(selectedCategory.categoryID, searchText, pageNo);
  };

  const fetchProducts = async (categoryID, searchText = "", pageNo = 1) => {
    try {
      setIsLoadingProducts(true);
      const data = await PostJsonData("/getProducts", {
        categoryID,
        searchText,
        pageNo,
      });
      setIsLoadingProducts(false);

      if (data.apiResponseCode === "1001") {
        setIsLoadingProducts(false);
        setProducts(data.data);
        setPageLimit(data?.pageLimit);
        setTotalRecords(data?.totalRecords);
      } else {
        setIsLoadingProducts(false);
        console.log("Error fetching products:", data.apiResponseMsg);
        setProducts([]);
      }
    } catch (error) {
      setIsLoadingProducts(false);
      console.log("Failed to fetch products:", error);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoadingCategories(true);
        const data = await PostJsonData("/getCategories", {});
        setIsLoadingCategories(false);

        if (data.apiResponseCode === "1001") {
          setCategories(data.data);
          if (data.data.length > 0) {
            setSelectedCategoryID(data.data[0]?.categoryID);
            fetchProducts(data.data[0]?.categoryID);
            setSelectedProduct(0);
          }
        } else {
          console.log("Error fetching categories:", data.apiResponseMsg);
        }
      } catch (error) {
        setIsLoadingCategories(false);
        console.log("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (selectedCategoryID) {
        fetchProducts(selectedCategoryID, searchText, pageNo);
      }
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const callGetData = (page) => {
    fetchProducts(selectedCategoryID, searchText, page);
  };

  return (
    <Container fluid>
      <Row className="main_container">
        <Col lg={2} className="outer_sidebar padding_0">
          <Sidebar />
        </Col>

        <Col lg={10} className="outer_main_container">
          <div className="main_home_outer">
            <Header setSearchText={setSearchText} searchText={searchText} />
            <Container fluid className="category">
              <Row className="productsContainer">
                <Col lg={1}>
                  <div className="category-title">Category</div>
                </Col>
                <Col className="categories-cont" lg={10}>
                  {isLoadingCategories ? (
                    <Row>
                      {Array.from({ length: 6 }).map((_, idx) => (
                        <Col key={idx} xs={6} sm={4} md={3} lg={2}>
                          <Skeleton
                            style={{ borderRadius: "10px" }}
                            height={50}
                            width={100}
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    categories?.map((category, index) => (
                      <div
                        className={`coffee ${
                          selectedProduct === index ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => handleProductClick(index)}
                      >
                        <div
                          className={`inner_category ${
                            selectedProduct === index ? "selected" : ""
                          }`}
                        >
                          {category?.categoryName}
                        </div>
                      </div>
                    ))
                  )}
                </Col>
              </Row>
            </Container>

            <Container fluid className="products">
              {isLoadingProducts ? (
                <Row className="cards_container">
                  {Array.from({ length: 12 }).map((_, idx) => (
                    <Col key={idx} xs={6} sm={4} md={3} lg={2}>
                      <Skeleton
                        style={{ borderRadius: "10px" }}
                        height={180}
                        width={"100%"}
                      />
                    </Col>
                  ))}
                </Row>
              ) : products?.length > 0 ? (
                <Cards
                  setSelectedProductData={setSelectedProductData}
                  products={products}
                  setShow={setShow}
                  isLoadingProducts={isLoadingProducts}
                />
              ) : (
                <div style={{ textAlign: "left", fontWeight: "600" }}>
                  No Record Found
                </div>
              )}
            </Container>
            {show && (
              <ModalCard
                selectedProduct={selectedProductData}
                setShow={setShow}
                show={show}
              />
            )}
            <div className="w-100">
              <PaginationComponent
                totalRecords={totalRecords}
                pageLimit={pageLimit}
                setTotalRecords={setTotalRecords}
                setPageLimit={setPageLimit}
                setPageNo={setPageNo}
                pageNo={pageNo}
                callGetData={callGetData}
              />
            </div>
            {orderStats && (
              <OrderStatsModal
                orderStats={orderStats}
                setOrderStats={setOrderStats}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Menu;
