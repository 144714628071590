import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormFeedback,
} from "@coreui/react";
// import { GoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostJsonData } from "../Api/globalApi";
import Swal from "sweetalert2";
import { useLoading } from "../Context/LoadingContext";

const LoginForm = () => {
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

  const generateSessionKey = () => {
    return Math.floor(
      100000000000000 + Math.random() * 900000000000000
    ).toString();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const sessionKey = generateSessionKey(); // Generate random session key
    setShowErrors(true);

    if (!email || !emailPattern.test(email) || !password) {
      return;
    }
    const jsonData = {
      username: email.trim(),
      password: password.trim(),
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/login", jsonData);

      if (response.apiResponseCode === "1001") {
        setLoading(false);
        localStorage.setItem("UserID", response.UserID);
        localStorage.setItem("roleID", response.roleID);
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("Name", response.Name);
        localStorage.setItem("profileImageURL", response.profileImageURL);
        localStorage.setItem("sessionKey", sessionKey);

        navigate("/home");
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: response.apiResponseMsg,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Login failed:", error);
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: error.message || "An unexpected error occurred.",
      });
    }
  };

  return (
    <CCol className="second_half">
      <div className="inner_second_half">
        <div className="heading_log">Login to your account</div>
        <CForm className="form_custom" noValidate onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel className="label_custom">Email</CFormLabel>
            <CFormInput
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              required
              invalid={showErrors && (!email || !emailPattern.test(email))}
            />
            <CFormFeedback invalid>
              {showErrors && !email
                ? "Email is required."
                : "Email is invalid."}
            </CFormFeedback>
          </div>
          <div className="mb-2">
            <CFormLabel className="label_custom">Password</CFormLabel>
            <CFormInput
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value.trim())}
              required
              invalid={showErrors && !password}
            />
            <CFormFeedback invalid>Password is required.</CFormFeedback>
          </div>
          <div className="forgot_link_outer">
            <CFormCheck id="flexCheckDefault" label="Remember me" />
            <div
              className="forgot_pass_link"
              onClick={() => navigate("/forgotPassword")}
            >
              Forgot password?
            </div>
          </div>
          <CButton type="submit" className="signIn_btn mt-3">
            Sign In
          </CButton>
        </CForm>
        {/* <div className="outerOR">
          <div className="firstInnerOr"></div>or
          <div className="firstInnerOr"></div>
        </div>
        <GoogleLogin /> */}
      </div>
    </CCol>
  );
};

export default LoginForm;
