import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { PostJsonData } from "../Api/globalApi";
import { CTooltip } from "@coreui/react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const OrderDetailsModal = (props) => {
  const [orderDetails, setOrderDetails] = useState(null);
  // const [count, setCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props?.viewDetails?.orderID && props.orderDetailShow) {
      fetchOrderDetails(props?.viewDetails?.orderID,props?.viewDetails?.buyerID);
    }
  }, [props?.viewDetails?.orderID, props.orderDetailShow]);

  const fetchOrderDetails = async (orderID, buyerID) => {
    setLoading(true);
    setError(null);
    try {
      const response = await PostJsonData("/Api/getOrderItems", { orderID, buyerID });
      if (response.apiResponseCode === "1001") {
        setOrderDetails(response.data);
        // const initialCounts = {};
        // response.data.forEach((item) => {
        //   initialCounts[item.productCartID] = item.quantity || 1; // Start quantity based on existing quantity
        // });
        // setCount(initialCounts);
      } else {
        setError(response.apiResponseMsg || "Failed to fetch order details");
      }
    } catch (err) {
      setError("An error occurred while fetching order details");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (!props.orderDetailShow) {
    return null;
  }

  return (
    <Modal
      show={props.orderDetailShow}
      onHide={() => props.setOrderDetailShow(false)}
      centered
      className="order-details-modal"
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title className="offcanvas-title">
          Order Details <MdKeyboardDoubleArrowRight /> #
          {props?.viewDetails?.orderNo}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div>Loading...</div>}
        {error && <div className="text-danger">{error}</div>}
        {/* {!loading && !error && orderDetails && ( */}
        {orderDetails?.map((item,index) => (
          <div key={index} className="item-container">
            <img
              src={
                process.env.REACT_APP_IMAGE_BASE_URL + item?.imageURL?.String
              } // Default if no URL
              alt="not found"
              className="item-image"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop in case fallback image also fails
                e.target.src = "./images/noimagefound.png"; // Set fallback image
              }}
            />

            <div className="item-details">
              <div className="item-text">{item.productName}</div>
              <div className="item-description">
                {(() => {
                  try {
                    const details = JSON.parse(item.productVariationDetail); // Parse the JSON string
                    if (Array.isArray(details)) {
                      return details
                        .map((detail) => `${detail.name}: ${detail.value}`)
                        .join(", "); // Join key-value pairs with a comma
                    } else {
                      return item.productVariationDetail; // Fallback if not an array
                    }
                  } catch (e) {
                    console.log("Error parsing productVariationDetail:", e);
                    return item.productVariationDetail; // Fallback to original string if parsing fails
                  }
                })()}
              </div>
              <CTooltip content="Quantity">
                <div className="counter-container">
                  {/* <button
                  className="counter-button"
                  //   onClick={() => decrement(item.productCartID)}
                >
                  -
                </button> */}
                  <span className="counter-value">{item?.quantity}</span>
                  {/* <button
                  className="counter-button"
                  //   onClick={() => increment(item.productCartID)}
                  >
                  +
                  </button> */}
                </div>
              </CTooltip>
            </div>
            <div className="item-price">${item?.price * item?.quantity}</div>
          </div>
        ))}
        {/* )} */}
      </Modal.Body>
    </Modal>
  );
};

export default OrderDetailsModal;
