import React, { useEffect, useState } from "react";
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import {
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CBadge,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
} from "@coreui/react";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../../Common/Pagination";
import OrderDetailsModal from "../../Components/OrderDetailsModal";
import { PostJsonData } from "../../Api/globalApi";
import { useLoading } from "../../Context/LoadingContext";
// import Swal from "sweetalert2";
import ChangeOrderStatus from "../../Components/ChangeOrderStatus";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";

const Home = () => {
  const { setLoading } = useLoading();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("");
  const [viewDetails, setViewDetails] = useState();
  const [orders, setOrders] = useState([]);
  // const [allOrdersData, setAllOrdersData] = useState([]);
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const [orderStats, setOrderStats] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusTotalRecords, setStatusTotalRecords] = useState({
    ALL: 0,
    ACCEPTED: 0,
    PREPARING: 0,
    DELIVERED: 0,
    CANCELLED: 0,
  });

  const getStatusBadge = (status, orderId) => {
    const badgeStyles = { padding: "7px 8px", cursor: "pointer" };

    switch (status) {
      case "ACCEPTED":
        return (
          <CBadge
            style={badgeStyles}
            color="secondary"
            onClick={() => handleBadgeClick(orderId, status)}
          >
            {status}
          </CBadge>
        );
      case "DELIVERED":
        return (
          <CBadge style={badgeStyles} color="success">
            {status}
          </CBadge>
        );
      case "PREPARING":
        return (
          <CBadge
            style={badgeStyles}
            color="warning"
            onClick={() => handleBadgeClick(orderId, status)}
          >
            {status}
          </CBadge>
        );
      case "CANCELLED":
        return (
          <CBadge style={badgeStyles} color="danger">
            {status}
          </CBadge>
        );
      default:
        return (
          <CBadge style={badgeStyles} color="secondary">
            {status}
          </CBadge>
        );
    }
  };

  const fetchOrders = async (activeKey = "", searchText = "", pageNo) => {
    // setLoading(true);
    setIsLoading(true);
    try {
      const response = await PostJsonData("/Api/getOrderList", {
        orderStatus: activeKey,
        searchText: searchText,
        pageNo: pageNo,
      });

      if (response.apiResponseCode === "1001") {
        setOrders(response.data);
        setPageLimit(response?.pageLimit);
        setTotalRecords(response?.totalRecords);
        setApiResponseMsg("");
      } else {
        setOrders([]);
        setApiResponseMsg(response.apiResponseMsg || "No order found");
      }
    } catch (error) {
      console.log("Error fetching orders:", error);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  const fetchOrdersByStatus = async (status, searchText, pageNo = 1) => {
    try {
      const response = await PostJsonData("/Api/getOrderList", {
        orderStatus: status,
        searchText: searchText,
        pageNo: pageNo,
      });

      if (response.apiResponseCode === "1001") {
        const totalRecords = response?.totalRecords;

        // Update state with functional state update to avoid overwriting previous values
        setStatusTotalRecords((prevState) => {
          const updatedState = { ...prevState };

          if (status === "") {
            updatedState.ALL = totalRecords;
          } else {
            updatedState[status] = totalRecords;
          }

          return updatedState;
        });
        if (status == "ACCEPTED") {
          localStorage.setItem("AcceptedOrders", totalRecords);
        }
        return response?.data; // Return the orders data for the given status
      } else {
        return []; // Return empty array if no data is found
      }
    } catch (error) {
      console.log("Error fetching orders by status:", error);
      return [];
    }
  };

  useEffect(() => {
    const statuses = ["", "ACCEPTED", "PREPARING", "DELIVERED", "CANCELLED"];
    statuses?.forEach(async (status) => {
      await fetchOrdersByStatus(status, "", 1);
    });
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchOrders(activeKey, searchText, 1);
      setPageNo(1);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    fetchOrders(key, searchText, 1);
    setPageNo(1);
  };

  useEffect(() => {
    if (location?.state?.section == "notification") {
      setActiveKey("ACCEPTED");
    }
    if (location?.state?.section == "order") {
      fetchOrders();
    }
  }, [location]);

  // ---------------------------------------   badge click - update status -------------------------//

  const handleBadgeClick = async (orderId, currentStatus) => {
    try {
      let newStatus;
      let confirmationMessage;

      // Define the new status and confirmation message based on the current status
      switch (currentStatus) {
        case "ACCEPTED":
          newStatus = "PREPARING";
          confirmationMessage =
            "you want to update the status to Preparing?";
          break;
        case "PREPARING":
          newStatus = "DELIVERED";
          confirmationMessage =
            "you want to update the status to Delivered?";
          break;
        default:
          return;
      }

      // Show SweetAlert confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: confirmationMessage,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        // Proceed with the API call
        setLoading(true);
        const response = await PostJsonData("/Api/updateOrderStatus", {
          orderID: orderId,
          orderStatus: newStatus,
        });

        if (response.apiResponseCode === "1001") {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.apiResponseMsg,
          }).then(() => {
            setActiveKey(newStatus);
            fetchOrders(newStatus);
            setOrderStats(false);
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: response.apiResponseMsg,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating order status:", error);
    }
  };

  const renderTableRows = (ordersToRender) => {
    return isLoading ? (
      Array.from({ length: 10 }).map((_, idx) => (
        <CTableRow key={idx}>
          <CTableDataCell>
            <Skeleton width={20} />
          </CTableDataCell>
          {/* <CTableDataCell>
            <Skeleton circle width={40} height={40} />
          </CTableDataCell> */}
          <CTableDataCell>
            <Skeleton width={100} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={150} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={100} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={100} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={50} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={50} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={60} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={60} />
          </CTableDataCell>
          <CTableDataCell>
            <Skeleton width={30} />
          </CTableDataCell>
        </CTableRow>
      ))
    ) : orders.length > 0 ? (
      ordersToRender?.map((order,index) => (
        <CTableRow key={index}>
          <CTableDataCell>
            <input className="form-check-input" type="checkbox" />
          </CTableDataCell>
          <CTableDataCell style={{ cursor: "pointer" }}>
            <div
              className="d-flex gap-2"
              onClick={() => {
                setOrderDetailShow(true);
                setViewDetails(order);
              }}
            >
              <div>
                <div>Order No: {order.orderNo}</div>
                <div className="table_id">ID: #{order.orderID}</div>
              </div>
            </div>
          </CTableDataCell>
          <CTableDataCell>
            {order.buyerName ? order.buyerName : "N/A"}
          </CTableDataCell>
          <CTableDataCell>
            {order.description.String ? order.description.String : "N/A"}
          </CTableDataCell>
          <CTableDataCell>
            <p className={order?.isPaid ? "paid-color" : "unpaid-color"}>
              {order.isPaid ? "Paid" : "Unpaid"}
            </p>
          </CTableDataCell>
          <CTableDataCell>${order.price}</CTableDataCell>
          <CTableDataCell>
            <p
              className={order?.isSubscription ? "paid-color" : "unpaid-color"}
            >
              {order?.isSubscription ? "Yes" : "No"}
            </p>
          </CTableDataCell>
          <CTableDataCell>
            {" "}
            {getStatusBadge(order.orderStatus, order.orderID)}
          </CTableDataCell>
          <CTableDataCell>
            {moment(order?.createdAt, "YYYY-MM-DD HH:mm:ss").format(
              "DD MMM YY, h:mm A"
            )}
          </CTableDataCell>
          <CTableDataCell>
            <CDropdown className="home_list_dropdown">
              <CDropdownToggle className="home_list_dropdownToggle">
                ...
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  onClick={() => {
                    setOrderDetailShow(true);
                    setViewDetails(order);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Order Details
                </CDropdownItem>
                <CDropdownItem
                  onClick={() => {
                    setViewDetails(order);
                    setOrderStats(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Change Order Status
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CTableDataCell>
        </CTableRow>
      ))
    ) : (
      <CTableRow>
        <CTableDataCell colSpan={20}>{apiResponseMsg}</CTableDataCell>
      </CTableRow>
    );
  };

  const callGetData = (page) => {
    fetchOrders(activeKey, searchText, page);
  };

  return (
    <>
      {orderDetailShow && (
        <OrderDetailsModal
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          orderDetailShow={orderDetailShow}
          setOrderDetailShow={setOrderDetailShow}
        />
      )}
      {orderStats && (
        <ChangeOrderStatus
          setActiveKey={setActiveKey}
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          orderStats={orderStats}
          setOrderStats={setOrderStats}
          fetchOrders={fetchOrders}
        />
      )}
      <Container fluid>
        <Row className="main_container">
          <Col lg={2} className="outer_sidebar padding_0">
            <Sidebar />
          </Col>
          <Col lg={10} className="outer_main_container">
            <div className="main_home_outer">
              <Header
                // activeKey={activeKey}
                setSearchText={setSearchText}
                // fetchOrders={fetchOrders}
                // handleSearch={handleSearch} // Pass the search handler to the Header
              />
              <div className="category home_table_tabs">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink
                      active={activeKey === ""}
                      onClick={() => handleTabChange("")}
                    >
                      All Orders {`(${statusTotalRecords?.ALL})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "ACCEPTED"}
                      onClick={() => handleTabChange("ACCEPTED")}
                    >
                      Accepted Orders {`(${statusTotalRecords?.ACCEPTED})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "PREPARING"}
                      onClick={() => handleTabChange("PREPARING")}
                    >
                      Preparing Orders {`(${statusTotalRecords?.PREPARING})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "DELIVERED"}
                      onClick={() => handleTabChange("DELIVERED")}
                    >
                      Delivered Orders {`(${statusTotalRecords?.DELIVERED})`}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === "CANCELLED"}
                      onClick={() => handleTabChange("CANCELLED")}
                    >
                      Cancelled Orders {`(${statusTotalRecords?.CANCELLED})`}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <CTabPane visible={activeKey === ""}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>All Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Buyer Details</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    {orders?.length ? (
                      <PaginationComponent
                        totalRecords={totalRecords}
                        pageLimit={pageLimit}
                        setTotalRecords={setTotalRecords}
                        setPageLimit={setPageLimit}
                        setPageNo={setPageNo}
                        pageNo={pageNo}
                        callGetData={callGetData}
                      />
                    ) : (
                      ""
                    )}
                  </CTabPane>
                  <CTabPane visible={activeKey === "ACCEPTED"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Accepted Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Buyer Details</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    {orders?.length ? (
                      <PaginationComponent
                        totalRecords={totalRecords}
                        pageLimit={pageLimit}
                        setTotalRecords={setTotalRecords}
                        setPageLimit={setPageLimit}
                        setPageNo={setPageNo}
                        pageNo={pageNo}
                        callGetData={callGetData}
                      />
                    ) : (
                      ""
                    )}
                  </CTabPane>
                  <CTabPane visible={activeKey === "PREPARING"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Preparing Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Buyer Details</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    {orders?.length ? (
                      <PaginationComponent
                        totalRecords={totalRecords}
                        pageLimit={pageLimit}
                        setTotalRecords={setTotalRecords}
                        setPageLimit={setPageLimit}
                        setPageNo={setPageNo}
                        pageNo={pageNo}
                        callGetData={callGetData}
                      />
                    ) : (
                      ""
                    )}
                  </CTabPane>
                  <CTabPane visible={activeKey === "DELIVERED"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Delivered Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Buyer Details</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    {orders?.length ? (
                      <PaginationComponent
                        totalRecords={totalRecords}
                        pageLimit={pageLimit}
                        setTotalRecords={setTotalRecords}
                        setPageLimit={setPageLimit}
                        setPageNo={setPageNo}
                        pageNo={pageNo}
                        callGetData={callGetData}
                      />
                    ) : (
                      ""
                    )}
                  </CTabPane>
                  <CTabPane visible={activeKey === "CANCELLED"}>
                    <CTable align="middle" caption="top" responsive>
                      <CTableCaption>Cancelled Orders</CTableCaption>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>Check</CTableHeaderCell>
                          <CTableHeaderCell>Order</CTableHeaderCell>
                          <CTableHeaderCell>Buyer Details</CTableHeaderCell>
                          <CTableHeaderCell>Description</CTableHeaderCell>
                          <CTableHeaderCell>Payment Status</CTableHeaderCell>
                          <CTableHeaderCell>Price</CTableHeaderCell>
                          <CTableHeaderCell>Subscription</CTableHeaderCell>
                          <CTableHeaderCell>Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Time</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>{renderTableRows(orders)}</CTableBody>
                    </CTable>
                    {orders?.length ? (
                      <PaginationComponent
                        totalRecords={totalRecords}
                        pageLimit={pageLimit}
                        setTotalRecords={setTotalRecords}
                        setPageLimit={setPageLimit}
                        setPageNo={setPageNo}
                        pageNo={pageNo}
                        callGetData={callGetData}
                      />
                    ) : (
                      ""
                    )}
                  </CTabPane>
                </CTabContent>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
