import React from "react";
import {
  CNavItem,
  CSidebarBrand,
  CSidebarHeader,
  CSidebarNav,
} from "@coreui/react";
import "../Login/Login.css";
import { GrHomeRounded } from "react-icons/gr";
import { PiSquaresFour } from "react-icons/pi";
import { GoPerson } from "react-icons/go";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { CiStar } from "react-icons/ci";

const Sidebar = () => {
  const location = useLocation();
  // const [activeTab, setActiveTab] = useState("home");

  const handleTabClick = (tab) => {
    navigate(`/${tab}`);
  };
  const navigate = useNavigate();

  return (
    <>
      <CSidebarHeader className="sidebar_custom_header">
        <CSidebarBrand>
          <img className="sidebar-logo-img" src="./images/dc-final-logo-02 (1).png" alt="Sidebar logo" />
        </CSidebarBrand>
      </CSidebarHeader>
      <CSidebarNav>
        <CNavItem
          href="/home"
          onClick={(e) => {
            handleTabClick("home");
            e.preventDefault();
          }}
          className={location?.pathname === "/home" ? "active" : ""}
        >
          <div className="sidebar_items">
            <GrHomeRounded className="sideBar_icons" />
            Home
          </div>
        </CNavItem>
        <CNavItem
          href="/menu"
          onClick={(e) => {
            handleTabClick("menu");
            e.preventDefault();
          }}
          className={location?.pathname === "/menu" ? "active" : ""}
        >
          <div className="sidebar_items">
            <PiSquaresFour className="sideBar_icons" />
            Menu
          </div>
        </CNavItem>
        {/* <CNavItem
          href="/subScriptions"
          onClick={() => handleTabClick("subScriptions")}
          className={location?.pathname === "/subScriptions" ? "active" : ""}
        >
          <div className="sidebar_items">
            <BsCardChecklist className="sideBar_icons" />
            Elite Pass
          </div>
        </CNavItem> */}
        <CNavItem
          href="/buyers"
          onClick={(e) => {
            handleTabClick("buyers");
            e.preventDefault();
          }}
          className={location?.pathname === "/buyers" ? "active" : ""}
        >
          <div className="sidebar_items">
            <GoPerson className="sideBar_icons" />
            Buyers
          </div>
        </CNavItem>
        <CNavItem
          href="/profile"
          // onClick={() => handleTabClick("profile")}
          onClick={(e) => {
            handleTabClick("profile");
            e.preventDefault();
          }}
          className={location?.pathname === "/profile" ? "active" : ""}
        >
          <div className="sidebar_items">
            <GoPerson className="sideBar_icons" />
            Profile
          </div>
        </CNavItem>
        <CNavItem
          href="/reviews"
          // onClick={() => handleTabClick("reviews")}
          onClick={(e) => {
            handleTabClick("reviews");
            e.preventDefault();
          }}
          className={location?.pathname === "/reviews" ? "active" : ""}
        >
          <div className="sidebar_items">
            <CiStar className="sideBar_icons" />
            Reviews
          </div>
        </CNavItem>
        <CNavItem
          href="#"
          // onClick={() => handleTabClick("help")}
          // onClick={(e) => {
          //   handleTabClick("help");
          //   e.preventDefault();
          // }}
          className={location?.pathname === "/help" ? "active" : ""}
        >
          <div className="sidebar_items">
            <IoAlertCircleOutline className="sideBar_icons" />
            Help
          </div>
        </CNavItem>
      </CSidebarNav>
    </>
  );
};

export default Sidebar;
