import React from "react";
import { Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLoading } from "../Context/LoadingContext";

const Cards = ({ products, setShow, setSelectedProductData }) => {
  const { setOffcanvasShow } = useLoading();
  const handleProductClick = (product) => {
    setSelectedProductData(product); // Set selected product details
    setShow(true); // Show the modal
  };

  return (
    <Container fluid className="main_card">
      <Row className="cards_container">
        {products?.map((product,index) => (
          <Col lg={2} md={3} sm={6} key={index}>
            <div className="card_main">
              {/* Image */}
              <div>
                {/* <img
                  src="./images/Coconut cream cold brew, vanilla cream cold brew 1.svg"
                  alt={`image not found`}
                  className="card_img"
                /> */}
                <img
                  src={process.env.REACT_APP_IMAGE_BASE_URL + product?.imageURL}
                  alt="not found"
                  className="card_img"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop in case fallback image also fails
                    e.target.src = "./images/noimagefound.png"; // Set fallback image
                  }}
                />{" "}
              </div>

              {/* Product Name */}
              <div className="card_para">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-name-${product.productID}`}>
                      {product.Name}
                    </Tooltip>
                  }
                >
                  <h4
                    className="card_heading"
                    style={{
                      overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                      width: "100%", // Ensures the text fits within the container
                      cursor: "pointer", // Indicates the text is interactive
                    }}
                  >
                    {product.Name}
                  </h4>
                </OverlayTrigger>

                {/* Description */}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-desc-${product.productID}`}>
                      {product.description}
                    </Tooltip>
                  }
                >
                  <p
                    className="card_p"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%", // Ensures the text fits within the container
                      cursor: "pointer", // Indicates the text is interactive
                    }}
                  >
                    {product.description.length > 20
                      ? `${product.description.slice(0, 20)}...`
                      : product.description}
                  </p>
                </OverlayTrigger>
              </div>

              {/* Pricing and Action */}
              <div className="card_pricing">
                <h2 className="card_price">${product.price}</h2>
                <img
                  onClick={() => {
                    setShow(true);
                    handleProductClick(product);
                    setOffcanvasShow(false);
                  }}
                  src="./images/Frame 33.svg"
                  alt="icon"
                  className="card_icon"
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Cards;
