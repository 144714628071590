import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "../../Common/Header";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../Common/Sidebar";
import { RiEditLine } from "react-icons/ri";
import ProfileModal from "../../Components/profileModal";
import { CButton, CFormInput, CFormLabel } from "@coreui/react";
import { PostFormData, PostJsonData } from "../../Api/globalApi";
import { useLoading } from "../../Context/LoadingContext";
import Swal from "sweetalert2";

const Profile = () => {
  const { setLoading } = useLoading();
  const [profileShow, setProfileShow] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const userID = localStorage.getItem("UserID");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    profilePicture: null,
    gender: "",
    isMarketing: 0,
  });

  // Fetch profile data on component mount
  useEffect(() => {
    fetchProfile();
  }, [userID, setLoading]);

  const fetchProfile = async () => {
    // setLoading(true);
    try {
      const response = await PostJsonData("/Api/getProfile", {
        userID: parseInt(userID, 10),
      });
      setProfileData(response.profileData[0]);
      setFormData({
        ...formData,
        firstName: response.profileData[0]?.firstName || "",
        lastName: response.profileData[0]?.lastName || "",
        mobileNumber: response.profileData[0]?.mobileNumber || "",
        gender: response.profileData[0]?.gender || "",
        isMarketing: response.profileData[0]?.isMarketing,
      });
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdateProfile = async () => {
    const updateFormData = new FormData();
    updateFormData.append("userID", parseInt(userID, 10));
    updateFormData.append("firstName", formData.firstName);
    updateFormData.append("lastName", formData.lastName);
    updateFormData.append("mobileNumber", formData.mobileNumber);
    updateFormData.append("gender", formData.gender);
    updateFormData.append("isMarketing", formData.isMarketing ? "1" : "0");

    if (formData.profilePicture) {
      updateFormData.append("profileImageURL", formData.profilePicture);
    }

    try {
      setLoading(true);
      const response = await PostFormData("/Api/updateProfile", updateFormData);
      if (response.apiResponseCode === "1001") {
        setLoading(false);
        localStorage.setItem("profileImageURL",response.profileImageURL)
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.apiResponseMsg,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            fetchProfile();
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: response.apiResponseMsg,
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setFormData({
        ...formData,
        profilePicture: file,
        profilePicturePreview: URL.createObjectURL(file), // Create a preview URL
      });
    }
  };

  const renderSkeleton = () => (
    <>
      <Row className="mb-5" style={{ alignItems: "center" }}>
        <Col lg={2} className="profile_photo">
          <div className="image_profile_cont">
            <Skeleton circle={true} height={120} width={120} />
          </div>
        </Col>
        <Col className="profile_name" lg={10}>
          <Skeleton width={200} height={20} />
        </Col>
      </Row>
      <div className="form-section">
        <h6 className="mb-4">
          <Skeleton width={150} height={20} />
        </h6>
        <Row className="profile_name_email">
          <Col lg={4} className="form-group">
            <CFormLabel>
              <Skeleton width={100} />
            </CFormLabel>
            <Skeleton height={40} />
          </Col>
          <Col lg={4} className="form-group">
            <CFormLabel>
              <Skeleton width={100} />
            </CFormLabel>
            <Skeleton height={40} />
          </Col>
        </Row>
        <Row className="profile_name_email">
          <Col lg={4} className="form-group">
            <CFormLabel>
              <Skeleton width={120} />
            </CFormLabel>
            <Skeleton height={40} />
          </Col>
          <Col lg={4} className="">
            <CFormLabel className="gender-label-profile">
              <Skeleton width={80} />
            </CFormLabel>
            <div className="gender-profile">
              <Skeleton width={100} height={20} />
              <Skeleton width={100} height={20} style={{ marginTop: 10 }} />
              <Skeleton width={120} height={20} style={{ marginTop: 10 }} />
            </div>
          </Col>
        </Row>
        <div className="custom-profile-check">
          <Skeleton width={20} height={20} />
          <div className="custom-pro-check-text">
            <Skeleton width={200} />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {profileShow && (
        <ProfileModal
          profileShow={profileShow}
          setProfileShow={setProfileShow}
        />
      )}
      <Container fluid>
        <Row className="main_container">
          <Col lg={2} className="outer_sidebar padding_0">
            <Sidebar />
          </Col>

          <Col lg={10} className="outer_main_container">
            <div className="main_home_outer">
              <Header />
              <Container fluid>
                <div className="category profile_bg">
                  <div className="profile_main">
                    {profileData ? (
                      <>
                        <Row className="mb-5" style={{ alignItems: "center" }}>
                          <Col lg={2} className="profile_photo">
                            <div className="image_profile_cont">
                              <img
                                src={
                                  formData.profilePicturePreview ||
                                  process.env.REACT_APP_IMAGE_BASE_URL +
                                    profileData?.profileImageURL ||
                                  "./images/noimagefound.png"
                                }
                                className="profile_img"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "./images/noimagefound.png";
                                }}
                                alt="profile"
                              />
                              <div className="profile_edit_outer">
                                <label
                                  htmlFor="profilePictureUpload"
                                  className="profile_edit_label cursor-pointer"
                                >
                                  <RiEditLine className="profile_edit text-gray-500 hover:text-gray-800" />
                                </label>
                                <input
                                  id="profilePictureUpload"
                                  type="file"
                                  onChange={handleImageChange}
                                  style={{ contentVisibility: "hidden" }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col className="profile_name" lg={10}>
                            {profileData?.firstName} {profileData?.lastName}
                          </Col>
                        </Row>
                        <div className="form-section">
                          <h6 className="mb-4">Personal details</h6>
                          <Row className="profile_name_email">
                            <Col lg={4} className="form-group">
                              <CFormLabel>Name</CFormLabel>
                              <CFormInput
                                type="text"
                                name="firstName"
                                value={
                                  formData.firstName || profileData.firstName
                                }
                                onChange={handleInputChange}
                              />
                            </Col>
                            <Col lg={4} className="form-group">
                              <CFormLabel>Surname</CFormLabel>
                              <CFormInput
                                type="text"
                                name="lastName"
                                value={
                                  formData.lastName || profileData.lastName
                                }
                                onChange={handleInputChange}
                              />
                            </Col>
                          </Row>
                          <Row className="profile_name_email">
                            <Col lg={4} className="form-group">
                              <CFormLabel>Phone</CFormLabel>
                              <CFormInput
                                type="text"
                                name="mobileNumber"
                                value={
                                  formData.mobileNumber ||
                                  profileData.mobileNumber
                                }
                                onChange={handleInputChange}
                              />
                            </Col>
                            <Col lg={4} className="">
                              <CFormLabel className="gender-label-profile">
                                Gender
                              </CFormLabel>
                              <div className="gender-profile">
                                <div>
                                  <input
                                    type="radio"
                                    id="MALE"
                                    name="gender"
                                    value="MALE"
                                    checked={formData.gender === "MALE"}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="MALE">Male</label>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    id="FEMALE"
                                    name="gender"
                                    value="FEMALE"
                                    checked={formData.gender === "FEMALE"}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="FEMALE">Female</label>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    id="UNSPECIFIED"
                                    name="gender"
                                    value="UNSPECIFIED"
                                    checked={formData.gender === "UNSPECIFIED"}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="UNSPECIFIED">
                                    Unspecified
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="custom-profile-check">
                                <input
                                  type="checkbox"
                                  name="isMarketing"
                                  checked={formData.isMarketing == "1"}
                                  onChange={handleInputChange}
                                />
                                <div className="custom-pro-check-text">
                                  Subscribe to Marketing Emails
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <CButton
                              color="primary"
                              onClick={handleUpdateProfile}
                              className="addToCart_button mt-3 btn btn-primary"
                            >
                              Update
                            </CButton>
                          </div>
                        </div>
                      </>
                    ) : (
                      renderSkeleton()
                    )}
                  </div>
                </div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
