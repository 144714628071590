import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { PostJsonData } from "../Api/globalApi";
import { useLoading } from "../Context/LoadingContext";
import Swal from "sweetalert2";

function OrderConfirmationModal(props) {
  const {
    setLoading,
    selectedCheckoutData,
    orderConfirm,
    setOrderConfirm,
    setOrderStatus,
  } = useLoading();
  const sessionKey = localStorage.getItem("sessionKey");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    value: 0,
    label: "Choose Buyer",
  });
  const [buyerOptions, setBuyerOptions] = useState([
    { value: 0, label: "Choose Buyer", isDisabled: true },
  ]);
  const [error, setError] = useState("");
  const [calculatedTaxDetails, setCalculatedTaxDetails] = useState([]);
  const [totalAmount, setTotalAmount] = useState(
    Number(selectedCheckoutData) || 0
  );

  useEffect(() => {
    const fetchBuyers = async () => {
      const payload = {
        isFilter: 1,
        isSubscription: 0,
        pageNo: 0,
        searchText: "",
      };

      setLoading(true);

      try {
        const response = await PostJsonData("/Api/getBuyers", payload);

        if (response && response.data && Array.isArray(response.data)) {
          const guestUser = { value: 1, label: "Guest User" };
          const options = response.data.map((buyer) => ({
            value: buyer.buyerID,
            label: `${buyer.name} (${
              buyer?.mobileNo ? buyer?.mobileNo : buyer.email
            })`,
          }));

          const updatedOptions = [
            { value: 0, label: "Choose Buyer", isDisabled: true },
            guestUser,
            ...options.filter((opt) => opt.value !== 1),
          ];

          setBuyerOptions(updatedOptions);
        } else {
          console.log("Invalid response format:", response);
          setError("Failed to load buyers. Please try again.");
        }
      } catch (err) {
        console.log("Error fetching buyers:", err);
        setError("Error fetching buyers.");
      } finally {
        setLoading(false);
      }
    };

    const fetchTaxConfiguration = async () => {
      const taxPayload = { type: "BILLING" };
      try {
        const taxResponse = await PostJsonData(
          "/Api/getTaxConfiguration",
          taxPayload
        );

        if (taxResponse?.data) {
          console.log("Tax Configuration Data:", taxResponse.data);
          // Calculate tax and total dynamically
          calculateTaxAndTotal(
            taxResponse?.data,
            Number(selectedCheckoutData) || 0
          );
        } else {
          console.log("Failed to fetch tax configuration:", taxResponse);
        }
      } catch (err) {
        console.log("Error fetching tax configuration:", err);
      }
    };

    fetchBuyers();
    fetchTaxConfiguration();
  }, [selectedCheckoutData]);

  const calculateTaxAndTotal = (taxes, amount) => {
    if (!Array.isArray(taxes)) return;

    const taxDetails = taxes?.map((tax) => {
      const taxAmount =
        tax?.type === "PERCENTAGE" ? (amount * tax?.value) / 100 : 0;
      return {
        name: tax.name,
        amount: taxAmount.toFixed(2),
      };
    });

    const totalTax = taxDetails?.reduce(
      (sum, tax) => sum + parseFloat(tax?.amount),
      0
    );

    setCalculatedTaxDetails(taxDetails);
    setTotalAmount((amount + totalTax).toFixed(2));
  };

  const handleCheckout = async () => {
    if (!selectedPaymentMethod || selectedPaymentMethod.value === 0) {
      setError("Please select a valid buyer.");
      return;
    } else {
      setError(""); // Clear error message if a valid buyer is selected
    }

    const payload = {
      buyerID: selectedPaymentMethod.value,
      description: "", // Consider adding description or removing if not needed
      sessionKey: sessionKey,
    };

    setLoading(true); // Set loading to true while making the request

    try {
      const response = await PostJsonData("/Api/setOrder", payload);

      // Check if API response code is '1001' (success)
      if (response.apiResponseCode === "1001") {
        setOrderStatus(true);
        setOrderConfirm(false);
        // }
        fetchCartProducts();
      } else {
        Swal.fire(
          "Error!",
          response.apiResponseMsg || "Failed to place the order.",
          "error"
        );
      }
    } catch (error) {
      console.log("Error placing the order:", error);
      Swal.fire(
        "Error!",
        "An error occurred while placing the order.",
        "error"
      );
    } finally {
      setLoading(false); // Stop loading once the API call is completed or failed
    }
  };

  const fetchCartProducts = async () => {
    const sessionKey = localStorage.getItem("sessionKey");
    const payload = {
      searchText: "", // Adjust as necessary
      sessionKey: sessionKey,
    };
    // setLoading(true);
    try {
      const response = await PostJsonData("/getCartProducts", payload);
      if (response.apiResponseCode === "1001") {
        // setLoading(false);
        localStorage.setItem(
          "cartCount",
          response.data ? response.data?.length : 0
        );
      } else {
        // setLoading(false);
        // Swal.fire(
        //   "Error!",
        //   response.apiResponseMsg || "Failed to fetch cart items.",
        //   "error"
        // );
        localStorage.setItem("cartCount", 0);
      }
    } catch (error) {
      // setLoading(false);
      console.log("Error fetching cart products:", error);
      // Swal.fire("Error!", "Failed to fetch cart products.", "error");
    }
  };

  return (
    <>
      {/* <PaymentButton/> */}
      <Modal
        className="order_custom_modal"
        show={orderConfirm}
        onHide={() => setOrderConfirm(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="offcanvas-title">
            Order Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-container">
          <div className="outer_order_content">
            <Form>
              <Form.Group controlId="paymentMethod">
                <Select
                  className="custom_select"
                  options={buyerOptions}
                  value={selectedPaymentMethod}
                  onChange={(option) => {
                    setSelectedPaymentMethod(option);
                    setError("");
                  }}
                  filterOption={(candidate, input) => {
                    if (candidate?.data?.value === 1) {
                      return true;
                    }
                    return candidate?.label
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  noOptionsMessage={() => "No other buyers found"}
                />
              </Form.Group>
              {error && <p className="text-danger mt-2">{error}</p>}
            </Form>
            <div className="order-summary">
              <div className="summary-row mt-3">
                <span>Subtotal</span>
                <span>${selectedCheckoutData}</span>
              </div>
              {calculatedTaxDetails?.map((tax, index) => (
                <div className="summary-row" key={index}>
                  <span>{tax.name}</span>
                  <span>${tax.amount}</span>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none", paddingTop: "0" }}>
          <Container fluid>
            <Row>
              <Col lg={4} className="p-0">
                <div className="total_price_order">Total Price</div>
                <div className="total_price_order_amnt">${totalAmount}</div>
              </Col>
              <Col lg={8} className="p-0">
                <Button className="order_pay_now" onClick={handleCheckout}>
                  Place Order
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderConfirmationModal;
