import axios from 'axios';

// const API_URL = "https://api.designerscollabcoffee.com";
const API_URL = process.env.REACT_APP_API_BASE_URL;

export const apiGet = async (endpoint) => {
  try {
    const response = await axios.get(`${API_URL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("GET request failed:", error);
    throw error;
  }
};

export const PostJsonData = async (endpoint, jsonData) => {
  try {
    const token = localStorage.getItem("accessToken");

    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: `${token}` }),
    };

    const response = await axios.post(`${API_URL}${endpoint}`, jsonData, {
      headers,
    });

    if (response.data?.apiResponseCode === "999") {
      console.log("Invalid authorization token detected.");
      localStorage.clear();
    }

    return response.data;
  } catch (error) {
    console.log("POST request failed:", error);
    throw error;
  }
};

export const PostFormData = async (endpoint, formData) => {
  try {
    const token = localStorage.getItem("accessToken");

    const headers = {
      "Content-Type": "multipart/form-data",
      ...(token && { Authorization: `${token}` }),
    };

    const response = await axios.post(`${API_URL}${endpoint}`, formData, {
      headers,
    });

    if (response.data?.apiResponseCode === "999") {
      console.log("Invalid authorization token detected.");
      localStorage.clear();
    }

    return response.data;
  } catch (error) {
    console.error("POST FormData request failed:", error);
    throw error;
  }
};