import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { PostJsonData } from "../Api/globalApi";
import Swal from "sweetalert2";
import { useLoading } from "../Context/LoadingContext";

const ModalCard = ({ show, setShow, selectedProduct }) => {
  const { setOffcanvasShow, setLoading } = useLoading();
  const sessionKey = localStorage.getItem("sessionKey");
  const [count, setCount] = useState(1);
  const [variations, setVariations] = useState(null);
  const [selectedVariations, setSelectedVariations] = useState({});
  const [selectedPrices, setSelectedPrices] = useState({});

  const increment = () => setCount(count + 1);
  const decrement = () => {
    if (count > 1) setCount(count - 1);
  };

  useEffect(() => {
    if (selectedProduct) {
      fetchProductVariations(
        selectedProduct.categoryID,
        selectedProduct.productID
      );
    }
  }, [selectedProduct]);

  const fetchProductVariations = async (categoryID, productID) => {
    setLoading(true);
    try {
      const data = await PostJsonData("/getProductVariations", {
        categoryID,
        productID,
      });
      setLoading(false);
      setVariations(data?.data);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching product variations:", error);
    }
  };

  const handleAddToCart = async (e) => {
    e.preventDefault();

    const productVariationValueIDs = Object.values(selectedVariations);

    const payload = {
      productID: selectedProduct.productID,
      productVariationValueID: productVariationValueIDs,
      quantity: count,
      sessionKey: sessionKey,
    };
    setLoading(true);
    try {
      const response = await PostJsonData("/setProductCart", payload);

      if (response.apiResponseCode === "1001") {
        fetchCartProducts();
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: response.apiResponseMsg,
          icon: "success",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setOffcanvasShow(true);
          }
        });
        setShow(false);
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: response.apiResponseMsg || "Something went wrong!",
          icon: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error adding to cart:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to add product to cart.",
        icon: "error",
      });
    }
  };

  const fetchCartProducts = async () => {
    const sessionKey = localStorage.getItem("sessionKey");
    const payload = {
      searchText: "", // Adjust as necessary
      sessionKey: sessionKey,
    };
    try {
      const response = await PostJsonData("/getCartProducts", payload);
      if (response.apiResponseCode === "1001") {
        localStorage.setItem(
          "cartCount",
          response.data ? response.data?.length : 0
        );
      } else {
        localStorage.setItem("cartCount", 0);
      }
    } catch (error) {
      console.log("Error fetching cart products:", error);
    }
  };

  // const handleVariationChange = (variationName, valueId, price) => {
  //   const parsedValueId = parseInt(valueId, 10); // Ensure valueId is an integer

  //   setSelectedVariations((prev) => ({
  //     ...prev,
  //     [variationName]: parsedValueId, // Store as an integer
  //   }));

  //   // Find the price for the selected variation
  //   const selectedPrice = variations
  //     ?.find((variation) => variation.name === variationName)
  //     ?.values?.find(
  //       (item) => item.productVariationValueID === parsedValueId
  //     )?.price;

  //   setSelectedPrices((prev) => ({
  //     ...prev,
  //     [variationName]: selectedPrice || 0,
  //   }));
  // };
  const handleVariationChange = (variationName, optionOrValueId) => {
    let valueId, price;

    if (typeof optionOrValueId === "object") {
      // Dropdown: option is an object with valueId and price
      ({ value: valueId, price } = optionOrValueId);
    } else {
      // Radio button: option is a valueId, find its price from the variations
      valueId = parseInt(optionOrValueId, 10); // Ensure valueId is an integer
      price =
        variations
          ?.find((variation) => variation.name === variationName)
          ?.values?.find((item) => item.productVariationValueID === valueId)
          ?.price || 0;
    }

    setSelectedVariations((prev) => ({
      ...prev,
      [variationName]: parseInt(valueId, 10),
    }));

    setSelectedPrices((prev) => ({
      ...prev,
      [variationName]: price,
    }));
  };

  useEffect(() => {
    if (variations?.length > 0) {
      const defaultSelections = {};
      const defaultPrices = {};

      variations?.forEach((variation) => {
        if (variation.values?.length > 0) {
          defaultSelections[variation.name] =
            variation.values[0]?.productVariationValueID;
          defaultPrices[variation.name] = variation.values[0]?.price;
        }
      });

      setSelectedVariations(defaultSelections);
      setSelectedPrices(defaultPrices);
    }
  }, [variations]);

  const calculateTotalPrice = () => {
    const basePrice = selectedProduct?.price || 0;
    const variationsPrice = Object.values(selectedPrices)?.reduce(
      (total, price) => total + (price || 0),
      0
    );
    return (basePrice + variationsPrice) * count;
  };

  return (
    <Modal className="customized-modal" size="lg" show={show} centered>
      <Modal.Header closeButton onClick={() => setShow(false)}>
        <Modal.Title>{selectedProduct?.Name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="add_toCart_modal_div">
            <Form.Label className="modal_title">
              {selectedProduct?.Name}
            </Form.Label>
            <Form.Text className="price_in_modal">
              <div
                className="counter-container"
                style={{
                  display: "inline-flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  className="counter-button"
                  onClick={decrement}
                >
                  -
                </button>
                <span className="counter-value">{count}</span>
                <button
                  type="button"
                  className="counter-button"
                  onClick={increment}
                >
                  +
                </button>
              </div>
              ${calculateTotalPrice().toFixed(2)}
            </Form.Text>
          </Form.Group>
          <Form.Group className="add_toCart_modal_div">
            <Form.Text className="product_description">
              {selectedProduct?.description}
            </Form.Text>
          </Form.Group>

          {variations &&
            variations?.map((variation,index) => (
              <Form.Group
                className="add_toCart_modal_div"
                key={index}
              >
                <Form.Label className="modal_custom_text">
                  {variation?.name}
                </Form.Label>
                <div>
                  {variation?.isDropdown === 1 ? (
                    <Select
                    menuPlacement="auto" // Automatically decides placement based on space
                    menuPosition="fixed" // Ensures menu positioning is independent of scroll
                      options={variation?.values?.map((value) => ({
                        value: value?.productVariationValueID,
                        label: value?.title,
                        price: value?.price,
                      }))}
                      value={variation.values
                        ?.map((value) => ({
                          value: value?.productVariationValueID,
                          label: value?.title,
                          price: value?.price,
                        }))
                        ?.find(
                          (option) =>
                            option?.value === selectedVariations[variation.name]
                        )}
                      onChange={(option) =>
                        handleVariationChange(variation?.name, option)
                      }
                    />
                  ) : (
                    variation?.values?.map((value) => (
                      <Form.Check
                        key={value?.productVariationValueID}
                        inline
                        className="modal_radio_buttons"
                        label={value?.title}
                        name={variation?.name}
                        type="radio"
                        id={`${variation?.name}-${value?.productVariationValueID}`}
                        onChange={() =>
                          handleVariationChange(
                            variation?.name,
                            value?.productVariationValueID,
                            value?.price
                          )
                        }
                        checked={
                          selectedVariations[variation?.name] ===
                          value?.productVariationValueID
                        }
                      />
                    ))
                  )}
                </div>
              </Form.Group>
            ))}
        </Form>
      </Modal.Body>

      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleAddToCart}
          className="addToCart_button mt-3"
          variant="primary"
        >
          Add To Cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCard;
